.contact-fab {
  color: #333;
  margin: 4rem 1rem;
  border: 2px #333 solid;
  border-radius: 30%;
  padding: 1rem;
  height: 12rem;
  width: 12rem;
}

.contact-fab:hover {
  background: #65afdd;
  color: #fff;
  cursor: pointer;
}

.contact-social {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}

h3 {
  margin: 5rem;
}
