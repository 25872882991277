.header {
  background: url("../../img/keyboard.jpg") no-repeat center center/cover;
  height: 100%;
  width: 100%;
  max-height: 25rem;
  color: #fff;
  text-align: center;
}

.header-content {
  margin: auto;
  font-size: 5rem;
}
