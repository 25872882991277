.email-form{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-sizing: border-box;
    background: #e5e5e8;
    box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
    text-align: start;
}

input::placeholder {
    color: gray;
}

label {
    margin-left: 1rem;
    color: #65afdd;
    font-weight: bold;
    font-size: 1.25rem;
}

.mt-10 {
    margin-top: 7rem;
    margin-bottom: 5rem;
}

input {
    background: #f9fafc;
    padding: 10px;
    margin: 1rem;
    padding-left: 20px;
    height: 50px;
    font-size: 14px;
    border-radius: 50px;
    box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}

textarea {
    background: #f9fafc;
    padding: 10px;
    padding-left: 20px;
    font-size: 14px;
    border-radius: 5px;
    box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}

.email-form-btn {
    color: white;
    margin-top: 20px;
    background: #65afdd;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    font-weight: 900;
    box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px white;
    transition: 0.5s;
}

.email-form-btn:hover {
    box-shadow: none;
}