.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  margin-left: 5%;
}
.nav {
  display: flex;
}

.nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav a {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  border-bottom: 3px transparent solid;
  padding-bottom: 0.5rem;
  transition: border-color 0.5s;
  margin: 1rem;
  font-size: 1.5rem;
}
.nav a:hover {
  border-color: #ccc;
}
.nav a.current {
  border-color: #65afdd;
}
