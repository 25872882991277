.example-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.example-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 3px 3px 3px rgba(3, 3, 3, 0.2);
}

.link {
  text-decoration: none;
  font-size: 1.5rem;
  margin-top: auto;
}

img {
  max-width: 100%;
  border-radius: 5px;
  margin: 1.5rem;
}

p {
  max-width: 20rem;
}
