.hero {
  display: flex;
  flex-direction: column;
  background: url("../../img/laptop.jpg") no-repeat center center/cover;
  height: 100vh;
  width: 100%;
  text-align: center;
  color: #ffffff;
  padding-bottom: 15rem;
  justify-content: flex-start;
}

.hero:before {
  content: "";
  background: rgba(8, 8, 8, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero-button {
  width: 10rem;
  position: sticky;
  color: #65afdd;
  background-color: #151515;
  border: solid 2px #65afdd;
}

.hero-typed {
  z-index: 1;
  margin: 7rem 0 20rem 0;
}

.hero-button:hover {
  width: 10rem;
  color: #ffffff;
  background-color: #65afdd;
  border: solid 2px #ffffff;
}

