.home-content {
  text-align: center;
  margin: 4rem 2rem;
}

.process h3 {
  margin: auto;
}

.bottom-line {
  height: 2px;
  width: 18rem;
  background: #65afdd;
  margin: 0 auto 1rem auto;
}

.lead {
  font-size: 1.3rem;
  margin: auto;
}

.section-title {
  font-size: 2rem;
  display: block;
  padding-bottom: 0.5rem;
  text-align: center;
  text-transform: uppercase;
}

.process-section {
  text-align: center;
}

.process {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 8rem 5rem;
}

.process-icon {
  border-radius: 10%;
  background: #333;
  color: #fff;
  width: 40%;
  height: 40%;
  padding: 1rem;
  text-align: center;
  position: relative;
  transition: all 1s;
}
.process-icon:hover {
  background: #65afdd;
  width: 42%;
  height: 42%;
}

.detail {
  margin: 1rem;
}
